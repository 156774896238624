// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src057508600/src/lanyard/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-lanyard-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/AboutLanyard.js" /* webpackChunkName: "component---src-pages-about-lanyard-js" */),
  "component---src-pages-agenda-page-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/AgendaPage.js" /* webpackChunkName: "component---src-pages-agenda-page-js" */),
  "component---src-pages-home-page-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsers-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/Sponsers.js" /* webpackChunkName: "component---src-pages-sponsers-js" */),
  "component---src-pages-thankyou-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/Thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-volunteers-js": () => import("/codebuild/output/src057508600/src/lanyard/src/pages/Volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */)
}

