module.exports = [{
      plugin: require('/codebuild/output/src057508600/src/lanyard/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src057508600/src/lanyard/src/components/layout.js"},
    },{
      plugin: require('/codebuild/output/src057508600/src/lanyard/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GOOGLE_ANALYTICS_TRACKING_ID"},
    },{
      plugin: require('/codebuild/output/src057508600/src/lanyard/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src057508600/src/lanyard/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
